<template>
    <RouterLink v-if="name" class="text-primary" :class="className" :to="{ name: name }" v-bind="propsLink">
        <slot></slot>
    </RouterLink>
    <a v-else class="text-primary" :class="className" :href="url" v-bind="propsLink"> <slot></slot></a>
</template>

<script setup>
import { routerNames } from "@/app/providers/router";
import { computed } from "vue";

const props = defineProps({
    name: { type: String, enum: Object.values(routerNames) },
    url: { type: String },
    simple: { type: Boolean, default: false },
    blank: { type: Boolean },
});

const className = computed(() =>
    props.simple
        ? ["py-1", "px-2", "border-round", "transition-duration-200", "hover:bg-blue-100"]
        : ["transition-duration-200", "hover:text-blue-600", "font-medium"],
);
const propsLink = computed(() => (props.blank ? { target: "_blank" } : {}));
</script>

<style lang="scss" scoped></style>
