import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

import { routerNames } from "@/app/providers/router";

import { useLocalStorage } from "@/shared/lib/browser";
import { TOKEN_KEY } from "@/shared/config";
import { useLoading, useNotification } from "@/shared/lib/composables";

import { SessionApi } from "../api";

import { useUserStore } from "@/entities/User";

export const useSessionStore = defineStore("session", () => {
    const router = useRouter();
    const userStore = useUserStore();

    const { value: tokenValue, setLSValue: setLSToken } = useLocalStorage(TOKEN_KEY);
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError, showSuccess } = useNotification();

    const token = ref(tokenValue);

    const isAuth = computed(() => !!token.value);

    function setToken(value) {
        setLSToken(value);
        token.value = value;
    }
    function login(data) {
        setToken(data.accessToken);

        userStore.setUserData(data.user);

        router.push({
            name: routerNames.PERSONAL_HOME,
        });
    }

    async function signin(payload) {
        try {
            startLoading();
            const data = await SessionApi.signin(payload);
            login(data);
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }
    async function signup(payload) {
        try {
            startLoading();

            if (!payload.referer) delete payload.referer;

            const data = await SessionApi.signup(payload);
            login(data);
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }
    async function forgotPassword(payload) {
        try {
            startLoading();
            const result = await SessionApi.forgotPassword(payload);

            return result;
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }
    async function resetPassword(payload) {
        try {
            startLoading();

            await SessionApi.resetPassword(payload);

            showSuccess({ message: "Пароль успешно изменен", life: 8000 });

            await router.push({ name: routerNames.SIGNIN });
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }
    async function logout() {
        try {
            await SessionApi.logout();

            await router.push({
                name: routerNames.MAIN,
            });

            setToken("");
            userStore.setUserData(null);
        } catch (e) {
            showError(e);
        }
    }

    return {
        token,
        isAuth,
        isLoading,
        signin,
        signup,
        logout,
        forgotPassword,
        resetPassword,
    };
});
