<template>
    <div class="flex align-items-center justify-content-between py-3 px-3 lg:px-4:xl:px-6 bg-primary fixed top-0 left-0 right-0 z-5">
        <div class="flex gap-3 align-items-center">
            <span class="pi md:hidden text-lg" :class="{ 'pi-bars': !isMenu, 'pi-times': isMenu }" @click="toggleMenu"></span>
            <img class="hidden md:block" src="@/shared/assets/images/logo.png" alt="Логотип" height="30" />
            <span class="md:hidden">M.S</span>
        </div>
        <ul class="flex flex-column cursor-pointer absolute top-100 left-0 right-0 w-full md:hidden surface-100 p-2" v-if="isMenu">
            <li
                class="px-3 py-2 border-round transition-duration-200 hover:bg-primary-reverse text-color"
                v-for="item in menuItems"
                @click="toggleMenu"
            >
                <RouterLink :to="{ path: item.route }">
                    <span>{{ item.label }}</span>
                </RouterLink>
            </li>
        </ul>
        <ul class="cursor-pointer hidden md:flex">
            <li class="px-3 py-2 border-round transition-duration-200 hover:bg-primary-reverse" v-for="item in menuItems">
                <RouterLink :to="{ path: item.route }">
                    <span>{{ item.label }}</span>
                </RouterLink>
            </li>
        </ul>
        <div class="flex gap-2">
            <template v-if="isAuth">
                <router-link :to="{ name: routerNames.PERSONAL_HOME }">
                    <SplitButton label="Личный кабинет" icon="pi pi-user" :model="profileMenuItems" />
                </router-link>
            </template>
            <template v-else>
                <router-link :to="{ name: routerNames.SIGNIN }">
                    <Button class="text-0 hover:border-0" label="Войти" text />
                </router-link>
                <router-link :to="{ name: routerNames.SIGNUP }">
                    <Button class="text-0 hover:bg-primary-reverse" label="Регистрация" outlined />
                </router-link>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";

import { router } from "@/app/providers/router";
import { routerNames } from "@/app/providers/router";

import { useSessionStore } from "@/entities/Session";

const sessionStore = useSessionStore();
const { isAuth } = storeToRefs(sessionStore);

const menuItems = computed(() => [
    {
        label: "Оплата заказов",
        route: isAuth.value ? "/personal/orders/unpaid" : "/",
    },
    {
        label: "Доставка и оплата",
        route: "/delivery-and-payment",
    },
    {
        label: "Контакты",
        route: "/contacts",
    },
]);
const profileMenuItems = ref([
    {
        label: "Профиль",
        icon: "pi pi-user",
        command: () => router.push({ name: routerNames.PERSONAL_PROFILE }),
    },
    {
        separator: true,
    },
    {
        label: "Выйти",
        icon: "pi pi-sign-out",
        command: () => sessionStore.logout(),
    },
]);
const isMenu = ref(false);

function toggleMenu() {
    isMenu.value = !isMenu.value;
}
</script>

<style lang="scss" scoped></style>
