<template>
    <div class="w-full h-screen flex flex-column">
        <Header />
        <main class="w-full flex-auto py-4 px-2 md:px-6 mt-7">
            <div class="flex flex-column align-items-center gap-4 mx-auto">
                <RouterView></RouterView>
            </div>
        </main>
    </div>
</template>

<script setup>
import { onMounted } from "vue";

import { Header } from "@/widgets/header";
import { useUserStore } from "@/entities/User";

const userStore = useUserStore();

onMounted(async () => {
    await userStore.getMy();
});
</script>

<style lang="scss" scoped></style>
