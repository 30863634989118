<template>
    <footer
        class="surface-100 w-full flex flex-column lg:flex-row gap-5 lg:gap-8 align-items-center py-4 lg:justify-content-around lg:align-items-start"
    >
        <ul class="flex flex-column gap-2 align-items-center lg:align-items-start" v-for="item in links">
            <li v-for="link in item">
                <Link :name="link.name">{{ link.text }}</Link>
            </li>
        </ul>
    </footer>
</template>

<script setup>
import { shallowRef } from "vue";

import { routerNames } from "@/app/providers/router";

import { Link } from ".";

const links = shallowRef([
    [
        { text: "Доставка и оплата", name: routerNames.DELIVERY_AND_PAYMENT },
        { text: "Возврат товара", name: routerNames.RETURN_GOODS },
    ],
    [
        { text: "Публичная оферта", name: routerNames.TERMS },
        { text: "Политика конфидициальности", name: routerNames.POLICY },
        { text: "Обработка персональных данных", name: routerNames.ACCEPT_POLICY },
    ],
    [{ text: "Контакты", name: routerNames.CONTACTS }],
]);
</script>

<style lang="scss" scoped></style>
