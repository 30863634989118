<template>
    <aside class="flex gap-1 flex-column align-items-start surface-100 py-4 px-2 mt-7 w-25rem">
        <div class="w-full select-none text-lg" v-for="item in items">
            <TreeItem :parent="null" :model="item" />
        </div>
    </aside>
</template>

<script setup>
import { ref } from "vue";

import { routerNames } from "@/app/providers/router";
import { TreeItem } from "..";

const items = ref([
    { label: "Закупки", icon: "pi pi-shopping-bag", routerName: routerNames.ADMIN_PURCHASE },
    {
        label: "Оплаты",
        icon: "pi pi-dollar",
        routerName: routerNames.ADMIN_PAYMENTS,
    },
    {
        label: "Бонусная программа",
        icon: "pi pi-book",
        isOpen: true,
        children: [
            {
                label: "Настройки",
                icon: "pi pi-cog",
                routerName: routerNames.ADMIN_BONUS_SETTINGS,
            },
            {
                label: "Исключения",
                icon: "pi pi-user-minus",
                routerName: routerNames.ADMIN_BONUS_EXCEPTION,
            },
        ],
    },
    { label: "Пользователи", icon: "pi pi-users", routerName: routerNames.ADMIN_USER, disabled: true },
]);
</script>
