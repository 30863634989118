<template>
    <div class="w-full h-screen flex flex-column">
        <AdminHeader />
        <div class="w-full flex-auto flex overflow-auto">
            <AdminSidebar />
            <main class="w-full flex flex-column gap-3 align-items-start flex-auto py-4 px-2 md:px-6 mt-7 overflow-auto">
                <RouterView></RouterView>
            </main>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useUserStore } from "@/entities/User";

import { AdminHeader } from "@/widgets/header";
import { AdminSidebar } from "@/widgets/sidebar";

const userStore = useUserStore();

onMounted(async () => {
    await userStore.getMy();
});
</script>

<style lang="scss" scoped></style>
