import { ref } from "vue";

export function useLoading() {
    const isLoading = ref(false);

    const timer = ref(null);

    function startLoading() {
        isLoading.value = true;
    }
    function finishLoading() {
        if (timer.value) clearTimeout(timer.value);

        timer.value = setTimeout(() => {
            isLoading.value = false;
        }, 200);
    }

    return {
        isLoading,
        startLoading,
        finishLoading,
    };
}
