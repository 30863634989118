import { httpClient } from "@/shared/api/http-client";

export class UserApi {
    static async getMy() {
        const response = await httpClient.get("/user/my");
        return response.data;
    }

    static async saveEdit(payload) {
        const response = await httpClient.patch("/user/update", payload);
        return response.data;
    }

    static async createCustomCashback(payload) {
        const { data } = await httpClient.post("/user/custom-cashback", payload);
        return data;
    }

    static async getCustomPercent() {
        const { data } = await httpClient.get("/user/custom-cashback");
        return data;
    }
}
