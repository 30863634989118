import { useToast } from "primevue/usetoast";

export const useNotification = () => {
    const toast = useToast();

    const DEFAULT_MESSAGE = "Упс, промох разработчика";

    function showInfo({ message = DEFAULT_MESSAGE, life = 3000 }) {
        toast.add({
            life: life,
            severity: "info",
            summary: "Информация",
            detail: message,
        });
    }
    function showSuccess({ message = DEFAULT_MESSAGE, life = 3000 }) {
        toast.add({
            life: life,
            severity: "success",
            summary: "Успешно",
            detail: message,
        });
    }
    function showWarning({ message = DEFAULT_MESSAGE, life = 3000 }) {
        toast.add({
            life: life,
            severity: "warn",
            summary: "Предупреждение",
            detail: message,
        });
    }
    function showError({ message = DEFAULT_MESSAGE, life = 3000 }) {
        toast.add({
            life: life,
            severity: "error",
            summary: "Ошибка",
            detail: message,
        });
    }

    return {
        showInfo,
        showSuccess,
        showWarning,
        showError,
    };
};
