import { APP_NAME } from "@/shared/config";

export function useLocalStorage(key) {
    const keyLS = `${APP_NAME}:${key}`;

    const valueLS = window.localStorage.getItem(keyLS);
    const value = valueLS ? JSON.parse(valueLS) : null;

    function setLSValue(value) {
        if (!value && value !== false) {
            return window.localStorage.removeItem(keyLS);
        }
        window.localStorage.setItem(keyLS, JSON.stringify(value));
    }

    return { value, setLSValue };
}
