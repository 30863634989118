<template>
    <div class="w-full select-none text-lg" @click="toggle">
        <template v-if="isNotUrl">
            <div
                class="flex gap-3 justify-content-between align-items-center px-3 py-3 border-round"
                :class="{ 'text-400': model.disabled, menu__item: !model.disabled }"
            >
                <div class="flex gap-3 align-items-center">
                    <span :class="model.icon"></span>
                    <span>{{ model.label }}</span>
                </div>
                <span v-if="!model.disabled" class="pi pi-angle-down"></span>
            </div>
        </template>
        <RouterLink v-else class="flex gap-3 align-items-center px-3 py-3 border-round" :to="{ name: model.routerName }">
            <span :class="model.icon"></span>
            <span>{{ model.label }}</span>
        </RouterLink>
        <div class="ml-3 mt-1" v-show="isOpen" v-if="isFolder">
            <TreeItem class="item" v-for="child in model.children" :parent="model" :model="child"> </TreeItem>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const props = defineProps({
    model: Object,
    parent: { type: [null, Object] },
});

const isOpen = ref(false);
const isFolder = computed(() => {
    return props.model.children && props.model.children.length;
});
const isNotUrl = computed(() => props.model.disabled || !props.model.routerName);

function toggle() {
    isOpen.value = !isOpen.value;
}

onMounted(() => {
    console.log(isOpen.value);
});
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";
.router-link-exact-active {
    @include styleclass("bg-blue-100 font-medium");
}
.menu__item:not(.router-link-exact-active):hover {
    @include styleclass("surface-200");
}
</style>
