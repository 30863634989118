<template>
    <div class="flex align-items-center justify-content-between py-3 px-3 lg:px-4:xl:px-6 bg-primary fixed top-0 left-0 right-0 z-5">
        <div class="flex gap-3 align-items-center">
            <img class="hidden md:block" src="@/shared/assets/images/logo.png" alt="Логотип" height="30" />
            <span class="md:hidden">M.S</span>
        </div>
        <div class="flex gap-2 align-items-center hover:bg-blue-400 py-1 px-2 border-round cursor-pointer" @click="toggleMenu">
            <span>{{ userData.name }} {{ userData.surname }}</span>
            <span class="pi pi-angle-down"></span>
        </div>
        <Menu ref="menuRef" :model="menuItems" :popup="true" />
    </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";

import Menu from "primevue/menu";

import { useUserStore } from "@/entities/User";
import { useSessionStore } from "@/entities/Session";

const userStore = useUserStore();
const sessionStore = useSessionStore();

const { userData } = storeToRefs(userStore);

const menuRef = ref();

const menuItems = ref([
    {
        label: "Выйти",
        icon: "pi pi-sign-out",
        command: () => sessionStore.logout(),
    },
]);

function toggleMenu(event) {
    menuRef.value.toggle(event);
}
</script>

<style lang="scss" scoped></style>
