import { TOKEN_KEY, API_URL, DADATA_URL, DADATA_TOKEN } from "@/shared/config";
import { useLocalStorage } from "@/shared/lib/browser";
import { useSessionStore } from "@/entities/Session";

import axios from "axios";

const httpClient = axios.create({
    baseURL: API_URL,
});
httpClient.interceptors.request.use(
    config => {
        const token = useLocalStorage(TOKEN_KEY).value;
        if (!!token) {
            config.headers.Authorization = "Bearer " + token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);
httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const { response } = error;
        const { status } = response;

        if (status === 401) {
            const storeSession = useSessionStore();
            storeSession.logout();
        }

        if (response?.data) throw { ...response.data, statusCode: response.status };

        throw error;
    },
);

const dadataClient = axios.create({
    baseURL: DADATA_URL,
    headers: {
        Authorization: `Token ${DADATA_TOKEN}`,
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
dadataClient.interceptors.response.use(
    response => response,
    error => {
        const { response } = error;

        if (response?.data) throw response.data;

        throw error;
    },
);

export { httpClient, dadataClient };
