import { computed, ref } from "vue";
import { defineStore } from "pinia";

import { USER_DATA_KEY } from "@/shared/config";
import { useLocalStorage } from "@/shared/lib/browser";
import { useNotification } from "@/shared/lib/composables";
import { UserApi } from "../api";

export const useUserStore = defineStore("user", () => {
    const { showError } = useNotification();

    const { value: userValue, setLSValue: setLSUserData } = useLocalStorage(USER_DATA_KEY);

    const userData = ref(userValue);

    const isRecipient = computed(() => {
        return userData.value?.phone && userData.value?.name && userData.value?.surname;
    });

    function setUserData(value) {
        setLSUserData(value);
        userData.value = value;
    }

    async function getMy() {
        try {
            const data = await UserApi.getMy();

            setUserData(data);
        } catch (e) {
            showError(e);
        }
    }

    async function saveEdit(user) {
        try {
            const data = await UserApi.saveEdit(user);
            await this.getMy();
            return data;
        } catch (e) {
            showError(e);
        }
    }

    return {
        userData,
        isRecipient,
        getMy,
        setUserData,
        saveEdit,
    };
});
