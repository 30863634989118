import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import DialogService from "primevue/dialogservice";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";

import App from "./App.vue";
import { router } from "./providers/router";

import "./providers/styles/index.css";
import "primeflex/primeflex.css";
import "primevue/resources/themes/aura-light-blue/theme.css";
import "primeicons/primeicons.css";
import { SENTRY_ENABLED } from "@/shared/config";

const app = createApp(App);

app.use(createPinia());
app.use(PrimeVue);
app.use(router);
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);

if (SENTRY_ENABLED) {
    Sentry.init({
        app,
        dsn: "https://42b44766ca5c6df1e4fed5acb833b3a7@o4507502876884992.ingest.de.sentry.io/4507502878785616",
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0,
    });
}

export default app;
