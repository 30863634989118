import { httpClient } from "@/shared/api/http-client";

export class SessionApi {
    static async signin(payload) {
        const response = await httpClient.post("/auth/signin", payload);
        return response.data;
    }
    static async signup(payload) {
        const response = await httpClient.post("/auth/signup", payload);
        return response.data;
    }
    static async forgotPassword(payload) {
        const response = await httpClient.post("/auth/forgot-password", payload);
        return response.data;
    }
    static async resetPassword(payload) {
        await httpClient.post("/auth/reset-password", payload);
    }
    static async logout() {
        await httpClient.delete("/auth/logout");
    }
}
