import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { setTitle } from "./guards";

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(setTitle);

export * from "./routerNames";
export * from "./guards";
